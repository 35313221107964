.pt-tile {
    padding: 0 30px 0 0;
    width: 100%;
    max-width: 400px;

    &:last-child {
        padding: 0 10px 0 0;
    }

    & a {
        text-decoration: none;
    }

    &.blue {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    &.blue-light {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    &.blue-purple {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    & .image {
        position: relative;

        & svg {
            position: absolute;
            bottom: 0;
        }
    }

    & .content {
        text-align: center;

        & p {
            margin: 0;
            padding: 20px 0 10px;
            text-transform: uppercase;
            color: #fff;
        }

        & span {
            display: inline-block;
            color: #fff;
            padding: 0 0 20px;
        }
    }
}
.header-logo {
    position: fixed;
    z-index: 999;
    top: 15px;
    left: 15px;

    & img {
        width: 80px;
    }

    &.active-scroll {
        transform: translateY(-100vh);
        opacity: 0;
        transition: transform 0s ease-in .3s, opacity .3s ease-in 0s;
    }

    &.active-menu {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0s ease-in 0s, opacity .3s ease-in .3s;
    }
}

.header-hamburger {
    position: fixed;
    top: 20px;
    right: 15px;
    z-index: 999;
    background-color: var(--color-blue-light);
    padding: 12px;
    border-radius: 100%;
    cursor: pointer;

    & #hamburger {
        width: 14px;
        height: 14px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        & span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #fff;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 2px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 6px;
            }

            &:nth-child(4) {
                top: 10px;
            }
        }

        &.open span {

            &:nth-child(1) {
                top: 6px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 6px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

.header-navigation {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform: translateY(-101%);
    transition: transform .4s cubic-bezier(.58,.3,.005,1);
    will-change: transform;

    max-height: 100vh;
    overflow: auto;

    &.active {
        transform: translateY(0);

        & .wrap {
    
            & .links {
    
                & .header-navigation-main {
                    transition-delay: .25s;
                    opacity: 1;
                    transform: translateY(0);
                }

                & .header-navigation-services {
                    transition-delay: .3s;
                    opacity: 1;
                    transform: translateY(0);
                }

                & .header-navigation-office {
                    transition-delay: .35s;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    & .wrap {
        padding: 100px 0 60px;
        background-color: var(--color-blue);

        & .links {
            max-width: 1600px;
            padding: 0 20px;
            margin: 0 auto;

            & .header-navigation-main {
                opacity: 0;
                transform: translateY(2em);
                transition: all .4s cubic-bezier(.58,.3,.005,1);

                & ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 20px;

                    & li {
                        font-family: var(--heading-font-family);
                        font-size: 24px;
                        font-weight: 600;
                        /* text-transform: uppercase; */
                        margin-bottom: 4px;

                        & a {
                            text-decoration: none;
                            color: #fff;

                            &:hover {
                                color: var(--color-blue-light);
                            }
                        }
                    }
                }
            }

            & .header-navigation-services {
                display: none;
                opacity: 0;
                transform: translateY(2em);
                transition: all .4s cubic-bezier(.58,.3,.005,1);

                & ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    & a {
                        text-decoration: none;

                        &:hover {
                            
                            & li {
                                background-color: rgba(255, 255, 255, 0.05);
                                transition: .2s;
                            }
                        }
                    
                        & li {
                            font-family: var(--heading-font-family);
                            font-weight: 600;
                            padding: 16px;
                            border-radius: 5px;
                            font-size: 18px;
                            color: #fff;
                            margin-bottom: 16px;
                            transition: .2s;
    
                            & span {
                                font-family: var(--base-font-family);
                                font-weight: 400;
                                display: block;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            & .header-navigation-office {
                opacity: 0;
                transform: translateY(2em);
                transition: all .4s cubic-bezier(.58,.3,.005,1);

                & ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 20px;

                    &:last-child {
                        margin: 0;
                    }
                    
                    & li {


                        & a {
                            text-decoration: none;
                            color: #fff;

                            &:hover {
                                color: var(--color-blue-light);
                            }
                        }
                    }

                    &.socials {
                        
                        & li {
                            display: inline-block;
                            margin-right: 20px;
                            font-size: 24px;

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    & svg {

        & path {
            fill: var(--color-blue);
        }
    }
}

@media (--viewport-sm-min) {
    .header-logo {
        top: 20px;
        left: 20px;
    
        & img {
            width: 120px;
        }
    }

    .header-hamburger {
        top: 30px;
        right: 20px;
        padding: 16px;

        & #hamburger {
            width: 18px;
            height: 18px;

            & span {
    
                &:nth-child(1) {
                    top: 3px;
                }
    
                &:nth-child(2),
                &:nth-child(3) {
                    top: 8px;
                }
    
                &:nth-child(4) {
                    top: 13px;
                }
            }

            &.open span {

                &:nth-child(1) {
                    top: 8px;
                }
    
                &:nth-child(4) {
                    top: 8px;
                }
            }
        }
    }

    .header-navigation {

        & .wrap {
            padding: 140px 0 80px;
        
            & .links {
                display: flex;
                justify-content: space-between;
                padding: 0 32px;

                & .header-navigation-main {
    
                    & ul {
                        margin: 0;
    
                        & li {
                            font-size: 40px;
                        }
                    }
                }

                & .header-navigation-services {
                    display: block;
                    width: 35%;
                }

                & .header-navigation-office {
    
                    & ul {
                        margin: 0 0 40px;
    
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
/**
 * Base
 */
:root {
    --site-background-color: var(--site-background-color, rgb(255, 255, 255));
}

/* Box sizing */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.5;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-lg-min) {
        font-size: 17px;
    }
}

/* Links */
a {
    color: #03acdd;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    transition: .3s;

    &:hover,
    &:focus {
        color: #004a8e;
        transition: .3s;
    }

    &.button {
        color: #fff;
        background-color: var(--color-blue-light);
        border: 1px solid var(--color-blue-light);
        display: inline-block;
        padding: 8px 30px;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: var(--color-blue);
            border: 1px solid var(--color-blue);
        }

        &--alt {
            color: var(--color-purple);
            background-color: #fff;
            border: 1px solid var(--color-purple);

            &:hover,
            &:focus {
                color: #fff;
                background-color: var(--color-purple);
            }
        }
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;
}

/* Image handling */
figure {
    margin: 0;
    width: 100%;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img {
        width: auto;
    }
}

svg {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font-family);
    font-weight: 700;
    /* text-transform: uppercase; */
}

ul.checked-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
        background: url("/dist/images/vinkje.png") no-repeat left 2px;
        background-size: 17px 13px;
        background-position: 6px;
        padding-left: 36px;
        margin-bottom: 8px;
    }
}

/* landingspagina */
.form--landingspagina {
    max-width: 700px;
    padding: 15px;
    margin: 0 auto 40px;

    & form {

        & label {
            color: #000;
        }

        & input {
            border: 1px solid grey;
        }

        & .custom-checkbox {

            & input[type=checkbox] {
                &:checked {

                    & ~ label {

                        &::before {

                            border-color: grey;
                        }
                    }
                }
            }

            & label {

                &::before {
                    border: 1px solid grey;
                }
            }
        }

        & .custom-radio {

            & input[type=radio] {
                &:checked {

                    & ~ label {

                        &::before {

                            border-color: grey;
                        }
                    }
                }
            }

            & label {

                &::before {
                    border: 1px solid grey;
                }
            }
        }

        & .custom-select {
            border: 1px solid grey;
        }
    }

    & .umbraco-forms-submitmessage {
        display: block;
        color: #004a8e;
        text-align: center;
    }
}

@media (--viewport-sm-min) {
    .form--landingspagina {
        margin: 0 auto 60px;
    }
}

@media (--viewport-md-min) {
    .form--landingspagina {
        margin: 0 auto 80px;
    }
}
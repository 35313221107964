.diensten-tile {
    margin-bottom: 50px;

    & a {
        text-decoration: none;
    }

    &.blue {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    &.blue-light {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    &.blue-purple {
        & path { fill: var(--color-blue) }
        & .content { background-color: var(--color-blue) }
    }

    & .image {
        position: relative;

        & svg {
            position: absolute;
            bottom: 0;
        }
    }

    & .content {
        text-align: center;

        & p {
            margin: 0;
            padding: 20px 0 10px;
            /* text-transform: uppercase; */
            font-family: var(--heading-font-family);
            font-weight: 700;
            font-size: 1.3em;
            letter-spacing: 1.1px;
            color: #fff;
        }

        & span {
            display: inline-block;
            color: #000;
            background-color: #fff;
            padding: 8px 24px;
            margin: 8px 0 20px;
        }
    }
}
.page-title-ipn {

    .container {

        .content {
            width: 100%;
            max-width: unset;

            h1 {
                text-align: center;
            }
        }
    }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.inschrijf-pagina-nieuw {
    position: relative;
    z-index: 2;
    margin-bottom: 60px;
    color: #000;

    .hidden-vue {
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    input[type=date] {
        border: 1px solid #d7d7d7;
    }

    .loader-placeholder {
        margin: 20px 0;
        padding: 20px;
        background-color: #ededed;
        text-align: center;
        display: none;
        align-items: center;
        justify-content: center;

        &.active {
            display: flex;
        }

        .loader {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='%23000000'%3E%3Cpath d='M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z'/%3E%3C/svg%3E");
            background-size: contain;
            width: 20px;
            height: 20px;
            animation: rotate 1.1s linear infinite;
        }

        .loading {
            margin-left: 5px;
        }
    }

    label {
        color: #000;
    }

    .ipn-wrap {
        max-width: 900px;
        margin: 0 auto;
        width: 100%;
        padding: 60px 50px;
        background-color: #fff;
        box-shadow: 0 0 20px #00000031;
        margin-top: -180px;

        .ipn-promotion {
            position: relative;
            background-color: #283583;
            color: #fff;
            padding: 8px 16px;
            margin-bottom: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-size: 15px;

            .ipn-promotion-label {
                position: absolute;
                top: -12px;
                left: -10px;
                font-size: 11px;
                font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                font-weight: 700;
                text-transform: uppercase;
                padding: 1px 6px;
                background-color: #03acdd;
                color: #fff;
                transform: rotate(-13deg);
            }

            .ipn-promotion-message {
                width: calc(100% - 22px);
            }

            .ipn-promotion-icon {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M6.0198 3.36C5.8798 3.36 5.7598 3.31 5.6598 3.21C5.5598 3.11 5.5098 2.99 5.5098 2.85V2.81C5.5098 2.67 5.5598 2.55 5.6598 2.45C5.7598 2.35 5.8798 2.3 6.0198 2.3H6.0798C6.2198 2.3 6.3398 2.35 6.4398 2.45C6.5398 2.55 6.5898 2.67 6.5898 2.81V2.85C6.5898 2.99 6.5398 3.11 6.4398 3.21C6.3398 3.31 6.2198 3.36 6.0798 3.36H6.0198ZM6.0398 9.55C5.91314 9.55 5.80647 9.50667 5.7198 9.42C5.63314 9.33333 5.5898 9.22667 5.5898 9.1V4.81C5.5898 4.67667 5.63314 4.56667 5.7198 4.48C5.80647 4.39333 5.91314 4.35 6.0398 4.35C6.17314 4.35 6.28314 4.39333 6.3698 4.48C6.45647 4.56667 6.4998 4.67667 6.4998 4.81V9.1C6.4998 9.22667 6.45647 9.33333 6.3698 9.42C6.28314 9.50667 6.17314 9.55 6.0398 9.55Z' fill='white'/%3E%3Ccircle cx='6' cy='6' r='5.5' stroke='white'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                width: 18px;
                height: 18px;
            }
        }

        h2.ipn-title {
            margin: 0 0 8px;
        }

        .ipn-age {

            .ipn-age-wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .age-radio {

                    input[type=radio] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;

                        &:checked {

                            & ~ label {
                                border-color: #03acdd;
                                box-shadow: 0 0 3px 1px #03aedd;

                                &::before {
                                    box-shadow: 0 0 0 0 transparent;
                                    color: #fff;
                                    border-color: #000;
                                    background-color: #fff;
                                }

                                &::after {
                                    display: block;
                                }
                            }
                        }
                    }

                    label {
                        padding: 8px 16px 8px 32px;
                        margin: 0;
                        border: 1px solid #d7d7d7;
                        cursor: pointer;
                        position: relative;
                        vertical-align: top;
                        white-space: nowrap;

                        &::before {
                            border-radius: 100%;
                            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                            pointer-events: none;
                            background-color: #fff;
                            border: 2px solid #000;
                            position: absolute;
                            top: 0.75rem;
                            left: 0.5rem;
                            display: block;
                            width: 18px;
                            height: 18px;
                            content: "";
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                            top: calc(.75rem + 6px);
                            left: calc(.5rem + 6px);
                            width: 6px;
                            height: 6px;
                            background-color: #03acdd;
                            border-radius: 100%;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

        .ipn-abo {
            margin-top: 36px;

            .ipn-abo-wrap {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
                gap: 16px;

                .ipn-abo-tile {

                    input[type=radio] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;

                        &:checked {

                            & ~ label {
                                /* border: 2px solid #03acdd; */
                                /* background-color: #ececec; */
                                box-shadow: 0 0 5px 2px #03aedd;

                                .button {
                                    color: #03acdd;
                                    background-color: #fff;

                                    .button-text::before {
                                        content: '';
                                        position: absolute;
                                        border-color: #03acdd;
                                        border-style: solid;
                                        border-width: 0 2px 2px 0;
                                        top: 4px;
                                        left: 0px;
                                        transform: rotate(45deg);
                                        height: 13px;
                                        width: 7px;
                                    }

                                    .button-text::after {
                                        content: 'Gekozen';
                                    }
                                }
                            }
                        }
                    }

                    label {
                        position: relative;
                        padding: 42px 20px 18px;
                        box-shadow: 0 0 12px #00000021;
                        margin: 0;
                        cursor: pointer;
                        transition: .2s;
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        &:hover {
                            box-shadow: 0 0 20px #00000048;
                        }

                        &.no-label {
                            padding: 18px 20px;
                            margin-top: 24px;
                            height: calc(100% -24px);
                        }

                        &.flexible .button-text::after {
                            content: 'Kies flexible';
                        }

                        &.comfort .button-text::after {
                            content: 'Kies comfort';
                        }

                        &.commit .button-text::after {
                            content: 'Kies commit';
                        }

                        .ipn-abo-label {
                            font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                            text-align: center;
                            color: #fff;
                            font-size: 12px;
                            text-transform: uppercase;
                            background-color: #283583;
                            border: 1px solid #283583;
                            padding: 2px;
                            text-decoration: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;

                            &.ipn-blue {
                                background-color: #1665A7;
                                border: 1px solid #1665A7;
                            }
                        }

                        .ipn-abo-title {
                            font-size: 24px;
                            margin: 0 0 4px;
                        }

                        .ipn-abo-subtitle {
                            font-size: 13px;
                            margin: 0 0 8px;
                            padding: 0 0 8px;
                            border-bottom: 1px solid #d7d7d7;
                        }

                        ul {
                            list-style-type: none;
                            padding: 0;
                            margin: 0 0 8px;
                            font-size: 12px;

                            li {
                                position: relative;
                                padding-left: 16px;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    border-color: #03acdd;
                                    border-style: solid;
                                    border-width: 0 2px 2px 0;
                                    top: 2px;
                                    left: 2px;
                                    transform: rotate(45deg);
                                    height: 10px;
                                    width: 6px;
                                }
                            }
                        }

                        .ipn-price {
                            font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                            font-weight: 700;
                            font-size: 22px;
                            margin-bottom: 20px;

                            .ipn-price-subfix {
                                font-size: 14px;
                                font-weight: 400;

                                .ipn-price-old {
                                    text-decoration: line-through;
                                }
                            }
                        }

                        .ipn-promotion {
                            font-size: 12px;
                            margin: 4px 0 12px;

                            .ipn-promotion-message {
                                width: 100%;
                            }
                        }

                        .button {
                            font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                            text-align: center;
                            color: #fff;
                            background-color: #03acdd;
                            border: 1px solid #03acdd;
                            padding: 8px 30px;
                            text-decoration: none;
                            width: 100%;
                            margin-top: auto;

                            .button-text {
                                position: relative;
                                display: inline-block;
                                padding-left: 16px;
                            }
                        }
                    }
                }
            }
        }

        .ipn-upgrades {
            margin-top: 36px;

            input[type=checkbox] {
                width: auto;
                box-sizing: border-box;
                padding: 0;
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {

                    & ~ label {
                        border-color: #03acdd;
                        box-shadow: 0 0 3px 1px #03aedd;

                        &::before {
                            box-shadow: 0 0 0 0 transparent;
                            color: #fff;
                            border-color: #000;
                            background-color: #fff;
                        }

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            label {
                padding: 8px 16px 8px 32px;
                margin: 0 0 12px;
                border: 1px solid #d7d7d7;
                cursor: pointer;
                position: relative;
                vertical-align: top;
                white-space: nowrap;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ipn-price {
                    font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                    font-weight: 700;
                    font-size: 22px;

                    .ipn-price-subfix {
                        font-size: 14px;
                        font-weight: 400;

                        .ipn-price-old {
                            text-decoration: line-through;
                        }
                    }
                }

                &::before {
                    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    pointer-events: none;
                    background-color: #fff;
                    border: 2px solid #000;
                    position: absolute;
                    top: 0.9rem;
                    left: 0.5rem;
                    display: block;
                    width: 18px;
                    height: 18px;
                    content: "";
                }

                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 1.1rem;
                    left: .9rem;
                    width: 5px;
                    height: 10px;
                    border: solid #03acdd;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .ipn-info {
            margin-top: 36px;

            .ipn-info-wrap {
                display: flex;
                justify-content: space-between;

                .ipn-form {
                    width: 62%;

                    .asterisk {
                        color: #ff1717;
                    }

                    label {
                        margin: 2px 0 2px;
                        font-size: 16px;
                    }

                    input[type=text],
                    input[type=tel],
                    input[type=email],
                    select {
                        border: 1px solid #d7d7d7;
                    }

                    .ipn-field-wrap {
                        display: flex;
                        gap: 8px;

                        .ipn-field {
                            width: 50%;
                        }
                    }

                    .signature-input {
                        position: relative;
                        margin: 0 0 22px;

                        .signature-input-wrap {
                            position: relative;

                            .signature-pad {
                                border: 1px solid #d7d7d7;
                                background-color: #ffffff;
                                width: 100%;
                            }

                            .signature-input-title {
                                position: absolute;
                                top: 12px;
                                left: 12px;
                                color: gray;
                                font-size: 14px;
                                pointer-events: none;
                            }
                        }

                        .signature-clear {
                            display: inline-block;
                            padding: 6px 14px;
                            background-color: #213A86;
                            color: #fff;
                            cursor: pointer;
                        }

                        .signature-hidden-input textarea {
                            position: absolute;
                            opacity: 0;
                            height: 1px;
                            width: 1px;
                            padding: 0;
                            margin: 0;
                            top: 36px;
                            left: 62px;
                        }
                    }

                    .custom-checkbox label::before {
                        border-color: #000;
                    }
                }

                .ipn-summary {
                    width: 33.33%;

                    .ipn-summary-wrap {
                        box-shadow: 0 0 20px #00000048;

                        h3 {
                            margin: 0;
                            padding: 12px;
                            color: #fff;
                            background-color: #213A86;
                            text-align: center;
                        }

                        .ipn-summary-info {
                            padding: 15px 20px 20px;

                            .ipn-summary-age,
                            .ipn-summary-abo {
                                font-size: 14px;
                            }

                            .ipn-summary-upgrades {
                                list-style-type: none;
                                padding: 0;
                                margin: 12px 0 8px;
                                font-size: 12px;

                                li {
                                    position: relative;
                                    padding-left: 16px;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        border-color: #03acdd;
                                        border-style: solid;
                                        border-width: 0 2px 2px 0;
                                        top: 2px;
                                        left: 2px;
                                        transform: rotate(45deg);
                                        height: 10px;
                                        width: 6px;
                                    }
                                }
                            }

                            .ipnsa-name {
                                font-weight: 700;
                                min-width: 62px;
                                display: inline-block;
                            }

                            .ipnsa-duration {

                            }

                            .ipn-promotion {
                                font-size: 12px;
                                margin: 24px 0 12px;
                            }

                            .ipn-price {
                                font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
                                font-weight: 700;
                                font-size: 22px;
                                margin-bottom: 4px;

                                .ipn-price-subfix {
                                    font-size: 14px;
                                    font-weight: 400;

                                    .ipn-price-old {
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ipn-submit {
            display: flex;
            align-items: center;

            &.hidden-vue {
                display: none;
            }

            input:disabled,
            input[disabled]{
                border: 1px solid #999999;
                background-color: #666666;
                color: #000;
                pointer-events: none;
            }

            .send-loader {
                display: none;
                margin-left: 12px;

                &.active {
                    display: flex;
                }

                .loader {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='%23000000'%3E%3Cpath d='M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z'/%3E%3C/svg%3E");
                    background-size: contain;
                    width: 20px;
                    height: 20px;
                    animation: rotate 1.1s linear infinite;
                }

                .loading {
                    margin-left: 5px;
                }
            }
        }
    }
}

.popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1014;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;

    &.hide {
        display: none;
    }

    .popup-background {
        position: fixed;
        z-index: 1013;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.7);
    }

    .popup-wrap {
        position: relative;
        z-index: 1015;
        background-color: #fff;
        padding: 30px 20px 0;
        width: 100%;
        max-width: 600px;
        max-height: 96vh;

        .popup-wrap-scroll {
            overflow: auto;
            max-height: calc(96vh - 60px);
            padding-bottom: 20px;
        }

        .popup-suptitle {
            position: absolute;
            top: -9px;
            left: -7px;
            font-size: 16px;
            font-family: Cabin Condensed,Helvetica,Arial,"sans-serif";
            font-weight: 700;
            text-transform: uppercase;
            padding: 1px 6px;
            background-color: #03acdd;
            color: #fff;
            transform: rotate(-13deg);
        }

        .close-btn {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 12px;
            right: 12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='%23000000'%3E%3Cpath d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z'/%3E%3C/svg%3E");
            background-size: contain;
            cursor: pointer;

            &:hover {
                transform: scale(1.07);
            }
        }

        h2 {
            margin: 0 0 12px;
        }
    }
}

@media (--viewport-md-min) {
    .popup {

        .popup-wrap {
            padding: 60px 50px 0;

            .popup-wrap-scroll {
                max-height: calc(96vh - 120px);
                padding-bottom: 60px;
            }

            .close-btn {
                width: 36px;
                height: 36px;
                top: 16px;
                right: 16px;
            }
        }
    }
}

@media (--viewport-sm-max) {
    .inschrijf-pagina-nieuw {

        .ipn-wrap {
            padding: 30px 20px;
            margin-top: -58px;

            .ipn-age {

            }

            .ipn-abo {

                .ipn-abo-wrap {

                    .ipn-abo-tile {

                        label {

                            &.no-label {
                                margin-top: 0;
                                height: calc(100%);
                            }
                        }
                    }
                }
            }

            .ipn-upgrades {

                label {
                    white-space: unset;
                    flex-direction: column;
                    align-items: flex-start;

                    &::before {
                        top: 0.7rem;
                    }

                    &::after {
                        top: 0.9rem;
                    }
                }
            }

            .ipn-info {

                .ipn-info-wrap {
                    flex-direction: column;

                    .ipn-form {
                        width: 100%;
                    }

                    .ipn-summary {
                        width: 100%;
                        margin-top: 36px;
                        margin-bottom: 36px;
                    }
                }
            }
        }
    }
}


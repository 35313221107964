.side-form {
    position: relative;
    z-index: 2;

    & .wrap {
        margin-top: 0;

        & svg {
            margin-bottom: -1px;
            
            & path {
                fill: var(--color-blue-light);
            }
        }

        & .content {
            background-color: var(--color-blue-light);
            padding: 25px;

            & h2 {
                color: #fff;
                margin: 0;
            }
        }
    }
}

@media (--viewport-md-min) {
    .side-form {
        
        & .wrap {
            margin-top: -180px;

            & .content {
                padding: 35px;
            }
        }
    }
}
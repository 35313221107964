.slider {
    overflow: hidden;

    & .flickity-viewport {
        overflow: visible;
    }

    & .flickity-prev-next-button {
        background-color: rgba(200, 200, 200, 0.4);
        color: var(--color-purple);
        width: 26px;
        height: 26px;

        &.next {
            right: calc(50% - 169px);
        }

        &.previous {
            left: calc(50% - 169px);
        }

        & .flickity-button-icon {
            left: 25%;
            top: 25%;
            width: 50%;
            height: 50%;
        }
    }
}

@media (--viewport-ms-min) {
    .slider {
        & .flickity-prev-next-button {
            width: 32px;
            height: 32px;

            &.next {
                right: calc(50% - 234px);
            }
    
            &.previous {
                left: calc(50% - 234px);
            }
        }
    }
}

@media (--viewport-sm-min) {
    .slider {
        & .flickity-prev-next-button {
            width: 38px;
            height: 38px;

            &.next {
                right: calc(50% - 344px);
            }
    
            &.previous {
                left: calc(50% - 344px);
            }
        }
    }
}

@media (--viewport-md-min) {
    .slider {
        & .flickity-prev-next-button {

            &.next {
                right: calc(50% - 438px);
            }
    
            &.previous {
                left: calc(50% - 438px);
            }
        }
    }
}

@media (--viewport-lg-min) {
    .slider {
        & .flickity-prev-next-button {
            width: 44px;
            height: 44px;

            &.next {
                right: calc(50% - 546px);
            }
    
            &.previous {
                left: calc(50% - 546px);
            }
        }
    }
}
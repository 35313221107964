.faq-section {
    position: relative;
    /* margin: 0; */
    /* padding: 30px 0; */

    .faq-section-background {
        position: absolute;
        height: calc(100% - 60px);
        top: 30px;
        left: 0;
        width: 100%;
        background: linear-gradient(70deg,#223985,#0b8dc6 50%);

        .before,
        .after {
            left: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: 20px;
            fill: #fff;
        }

        .before {
            top: 0;
        }

        .after {
            bottom: 0;
        }
    }

    .faq-section-wrap {
        position: relative;
        max-width: 720px;
        margin: 0 auto;
        background-color: #f5f5f5;
        padding: 22px;
        /* transform: translateY(-40px); */

        .before,
        .after {
            top: 0;
            display: none;
            position: absolute;
            width: 20px;
            height: 100%;
            fill: #f5f5f5;
        }

        .before {
            right: -20px;
        }

        .after {
            left: -20px;
        }

        .faq-title {
            margin: 0 0 6px;
        }
    }
}

.faq-tile {
    border-bottom: 1px solid #000;

    &:last-child {
        border: none;
    }

    .accordion {
        /* background-color: #efefef; */
        background-color: transparent;
        cursor: pointer;
        padding: 18px 0;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 16px;
        transition: 0.4s;
        display: flex;
        align-items: center;

        &.active .icon {
            &:after {
                display: none;
            }
        }
    }

    .icon {
        display: block;
        border: 2px solid var(--color-purple);
        color: var(--color-purple);
        width: 24px;
        height: 24px;
        min-width: 24px;
    }

    .accordion .icon {
        position: relative;

        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            width: 10px;
            height: 2px;
            background-color: var(--color-purple);
        }

        &:after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            width: 2px;
            height: 10px;
            background-color: var(--color-purple);
        }
    }

    .title {
        display: block;
        padding-left: 12px;
        font-weight: 900;
        color: var(--color-purple);
        font-family: var(--base-font-family);
    }

    .panel {
        padding: 0 18px;
        display: none;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        &.active {
            display: block;
        }
    }
}

@media (--viewport-md-min) {
    .faq-section {
        /* padding: 40px 0; */

        .faq-section-background {
            height: calc(100% - 100px);
            top: 50px;
        }

        .faq-section-wrap {
            padding: 32px;
            /* border-radius: 24px; */
            /* transform: translateY(-60px); */

            .before,
            .after {
                display: block;
            }
        }
    }

    .faq-tile {

        .accordion {
            font-size: 20px;
        }

        .icon {
            width: 30px;
            height: 30px;
            min-width: 30px;
        }

        .accordion .icon {

            &:before {
                width: 12px;
            }

            &:after {
                height: 12px;
            }
        }
    }
}

.carousel-cell {
    position: relative;
    width: 100%;
    max-width: 280px;
    overflow: hidden;
    margin: 0 10px;
    z-index: 1;
    transform: scale(0.9, 0.9);
    transition: transform .6s;

    &.is-selected {
        transform: scale(1, 1);
        transition: transform .6s;
        z-index: 3;
    }

    & .slider-image {
        border: solid 1px #FFF;
    
            & img {
                width: 100%;
        }
    }
        
    & svg.before {
        display: block;

        position: absolute;
        z-index: 1;
        bottom: -5%;
        left: 0;
        height: 110%;

        & path {
            fill: #fff;
        }
    }

    & svg.after {
        display: block;

        position: absolute;
        z-index: 1;
        right: 0;
        top: -5%;
        height: 110%;

        & path {
            fill: #fff;
        }
    }
}

@media (--viewport-ms-min) {
    .carousel-cell {
        max-width: 400px;
    }
}

@media (--viewport-sm-min) {
    .carousel-cell {
        max-width: 600px;
    }
}

@media (--viewport-md-min) {
    .carousel-cell {
        max-width: 800px;
        margin: 0;
    }
}

@media (--viewport-lg-min) {
    .carousel-cell {
        max-width: 1000px;
        margin: 0;
    }
}
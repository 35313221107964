.review-slider {
    overflow: hidden;
    margin-bottom: 0;

    & .angle-overlay {

        & svg {
            display: block;

            & path {
                fill: var(--color-gray-light);
            }
        }
    }

    & .background {
        background-color: var(--color-gray-light);
        padding: 30px 0 40px;
        text-align: center;

        & .container {
            max-width: 1200px;
            padding-left: 0;
            padding-right: 0;

            & h2 {
                margin: 0 0 30px;
            }
    
            & .flickity-prev-next-button {
                background-color: rgba(200, 200, 200, 0.4);
                color: var(--color-purple);
                width: 38px;
                height: 38px;
    
                &.next {
                    right: 10px;
                }
    
                &.previous {
                    left: 10px;
                }
    
                & .flickity-button-icon {
                    left: 25%;
                    top: 25%;
                    width: 50%;
                    height: 50%;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .review-slider {
    
        & .background {
            padding: 40px 0 80px;
    
            & .container {
    
                & h2 {
                    margin: 0 0 30px;
                }
        
                & .flickity-prev-next-button {

                }
            }
        }
    }
}

@media (--viewport-xl-min) {
    .review-slider {
    
        & .background {
    
            & .container {
                padding-left: 15px;
                padding-right: 15px;
        
                & .flickity-prev-next-button {
        
                    &.next {
                        right: -50px;
                    }
        
                    &.previous {
                        left: -50px;
                    }
                }
            }
        }
    }
}
.review-tile {
    width: 100%;
    text-align: center;
    padding: 0 60px;
    transform: scale(0.9, 0.9);
    transition: transform .6s;

    &.is-selected {
        transform: scale(1, 1);
        transition: transform .6s;
    }

    & img {
        border-radius: 100%;
        max-width: 160px;
        margin: 0 auto;
    }

    & h3 {
        font-size: 1.1em;
        margin: 20px 0 10px;
    }

    & p {
        font-size: 16px;
        margin: 10px 0;
    }
}

@media (--viewport-md-min) {
    .review-tile {
        width: calc(100% / 3);
        padding: 0 30px;

        & img {
            max-width: 220px;
        }
    }
}
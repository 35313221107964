.abonnement-container {
    margin-bottom: 0;

    & svg {
        display: block;

        & path {
            fill: var(--color-gray-light);
        }
    }

    & .background {
        background-color: var(--color-gray-light);
        padding: 30px 0 40px;

        & .wrap {

            & .content {
                text-align: center;
                max-width: 1000px;
                margin: 0 auto 20px;

                & h2 {
                    margin: 0 0 10px;
                    font-size: 1.5em;
                }

                & p {
                    margin: 0;
                }
            }

            & .tiles {
                max-width: 1200px;
                margin: 0 auto 20px;

                & a {
                    display: block;
                    margin: 0 0 15px;
                    text-decoration: none;

                    & .upgrade-tile {
                        background-size: cover;
                        background-position: center;
                        padding: 50px 10px;
                        text-align: center;
                        color: #fff;

                        & img {
                            max-width: 48px;
                        }

                        & span {
                            font-weight: 900;
                            font-size: 24px;
                        }
                    }
                }
            }

            & .button-abbo {
                text-align: center;
            }
        }
    }
}

@media (--viewport-md-min) {
    .abonnement-container {

        & .background {
            padding: 50px 0 80px;

            & .wrap {

                & .content {
                    margin: 0 auto 30px;

                    & h2 {
                        font-size: 2em;
                    }
                }

                & .tiles {
                    display: flex;

                    & a {
                        width: 50%;
                        margin: 0 10px 20px;

                        & .upgrade-tile {
                            padding: 70px 10px;

                            & img {
                                max-width: 64px;
                            }
                        }
                    }
                }
            }
        }
    }
}




/*

.inschrijf-pagina {

    & svg {
        margin-bottom: -1px;

        & path {
            fill: var(--color-gray-light);
        }
    }

    & .wrap {
        background-color: var(--color-gray-light);
        padding: 15px;

        form {
            & h3 {
                margin: 20px 0 4px;
                color: #505050;
            }

            & .abbo-wrap {

                & .abbo-container {
                    position: relative;

                    & input[type=radio] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                        bottom: 0;
                        left: 50%;

                        &:checked + .abbo-label .abbo-content {
                            border: 4px var(--color-blue-light) solid;
                        }
                    }

                    & input[type=checkbox] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;

                        &:checked + .abbo-label .abbo-content {
                            border: 4px var(--color-blue-light) solid;
                        }
                    }

                    & .abbo-label {
                        display: block;
                        color: #000;
                        text-align: center;

                        & .abbo-content {
                            background-color: #fff;
                            border: 0px #fff solid;
                            padding: 40px 20px;
                            cursor: pointer;
                            background-position: center;
                            background-size: cover;

                            & img {
                                width: 100%;
                                max-width: 84px;
                            }

                            & span {
                                display: block;
                                color: #03acdd;
                                font-size: 1.2em;
                                font-weight: 900;

                                &.weken {
                                    font-size: 1.5em;
                                    text-transform: uppercase;
                                }

                                &.abonnement {
                                    margin: 0 0 16px;
                                    color: #535353;
                                }

                                &.kosten {
                                    margin: 16px 0 0;
                                    color: #535353;
                                }
                            }

                            &--leeftijd {
                                padding: 60px 20px;
                            }

                            &--tijd {

                                & img {
                                    opacity: .5;
                                }
                            }

                            &--upgrade {

                                & span {
                                    color: #fff;

                                    &.abonnement {
                                        margin: 0 0 16px;
                                        color: #fff;
                                    }

                                    &.kosten {
                                        margin: 16px 0 0;
                                        color: #fff;
                                    }
                                }
                            }

                            &--meest-gekozen {
                                position: relative;
                                overflow: hidden;

                                & .corner-ribbon {
                                    width: 200px;
                                    background: #2c7;
                                    position: absolute;
                                    top: 25px;
                                    left: -50px;
                                    text-align: center;
                                    font-size: 16px;
                                    line-height: 42px;
                                    color: #fff;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    box-shadow: 0 0 3px rgba(0,0,0,.3);
                                }
                            }
                        }
                    }
                }
            }

            & label {
                color: #000;
            }

            & .custom-checkbox label:before {
                border: 1px solid #000;
            }

            & input[type=submit] {
                float: right;
            }

            & .checked-list {
                display: none;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .inschrijf-pagina {

        & .wrap {
            padding: 30px;

            form {

                & .abbo-wrap {
                    display: flex;
                    margin-left: -10px;
                    margin-right: -10px;

                    & .abbo-container {
                        width: calc(100% / 3);
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .inschrijf-pagina {

        & .wrap {
            padding: 35px;

            form {

                & .abbo-wrap {
                    margin-left: -20px;
                    margin-right: -20px;

                    & .abbo-container {
                        padding: 0 20px;
                    }
                }

                & .checked-list {
                    display: block;
                }
            }
        }
    }
}

*/
.rooster-overzicht {

    & .wrap {
        max-width: 800px;

        & .custom-select {
            max-width: 400px;
            border-bottom: #000 solid 2px;
            background-position: right .4em top 50%,0 0;

            & select {
                padding: 8px 2px;
                font-weight: 900;
                text-transform: uppercase;
            }
        }
    }

    & .content {
        max-width: 800px;
    }
}
html {
    scroll-behavior: smooth;
}

.hero-tijdelijk {
    margin-top: 0;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, .2));

    & .container {

        & .content {
            max-width: 800px;
            padding: 100px 0 90px;

            & h1 {
                margin: 0;
                color: #fff;
                line-height: 1.2;
            }

            & p {
                margin: 0px 0 20px;
                color: #fff;
                font-size: 18px;
            }

            & .button {
                font-size: 16px;
            }
        }

    }

    & .background-image {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        & figure {
            height: 100%;

            & img {
                object-fit: cover;
                height: 100%;
            }

            & video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    & .angle-overlay {
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: 110%;

        & svg {
            display: block;

            & path {
                fill: #fff;
            }
        }
    }
}

@media (--viewport-md-min) {
    .hero-tijdelijk {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.52), rgba(255, 255, 255, 0));
    
        & .container {
    
            & .content {
                max-width: 800px;
                padding: 160px 0 180px;
    
                & h1 {
                    font-size: 60px;
                    line-height: 1.1;
                }
    
                & p {
                    font-size: 24px;
                }
    
                & .button {
                    font-size: 20px;
                }
            }
    
        }
    }
}

@media (--viewport-lg-min) {
    .hero-tijdelijk {
    
        & .container {
    
            & .content {
                max-width: 800px;
                padding: 220px 0 240px;
    
                & h1 {
                    font-size: 80px;
                }
            }
    
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    svg {
        display: none !important;
    }
}
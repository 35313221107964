.contact-content {

    & .wrap {

        & .contact-info {

            & h2 {
                margin-top: 0;
            }
        }

        & .contact-gegevens {
            margin-bottom: 30px;

            & h3 {
                color: #505050;
                margin-bottom: 8px;
            }

            & .gegevens,
                & .openingstijden {
                    
                    & table {
                        width: 100%;
                    }
                }
        }
    }
}

@media (--viewport-md-min) {
    .contact-content {

        & .wrap {
            margin-right: 80px;

            & .contact-info {

                & h2 {
                    font-size: 2em;
                    margin-bottom: 4px;
                }

                & p {
                    margin-top: 4px;
                }
            }
    
            & .contact-gegevens {
                display: flex;

                & .gegevens,
                & .openingstijden {
                    
                    & table {
                        min-width: 280px;
                        max-width: 400px;
                    }
                }
            }
        }
    }
}
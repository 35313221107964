.cta-big {
    position: relative;
    overflow: hidden;
    margin-top: 0;

    & .wrap {
        /* background-color: var(--color-blue-light); */
        background: #223985;
        background: linear-gradient(90deg, #223985 0%, #0b8dc6 100%);

        & .content {
            padding: 40px 20px 60px;

            & h2 {
                margin: 0 0 20px;
                font-size: 1.5em;
                line-height: 1.2;
                color: #fff;
            }

            & .button {
                color: #000;
                background-color: #fff;
                border-color: #fff;

                &:hover {
                    color: #fff;
                    background-color: var(--color-blue-light);
                    border-color: var(--color-blue-light);
                }
            }
        }

        & .image {
            display: none;
        }
    }

    & svg {
        display: block;
        position: absolute;
        z-index: 2;
        bottom: -1px;
        width: 100%;

        & path {
            fill: #fff;
        }
    }

    &.reverse .svg-bottom {
        transform: scaleX(-1);
    }
}

@media (--viewport-md-min) {
    .cta-big {
        position: relative;
        overflow: hidden;
    
        & .wrap {
            display: flex;
            background: linear-gradient(90deg, #223985 0%, #0b8dc6 50%);
    
            & .content {
                align-self: center;
                width: 50%;
                padding: 50px 50px 90px;
    
                & h2 {
                    font-size: 40px;
                }
            }
    
            & .image {
                display: block;
                width: 50%;
                position: relative;
                overflow: hidden;
    
                & figure {
                    height: 100%;
    
                    & img,
                    & video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
    
                & svg {
                    display: block;
                    width: auto;

                    position: absolute;
                    z-index: 1;
                    left: -1px;
                    bottom: -5%;
                    height: 110%;
        
                    & path {
                        fill: #0b8dc6;
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .cta-big {
    
        & .wrap {
    
            & .content {
                padding: 80px 80px 120px 220px;
    
                & h2 {
                    font-size: 60px;
                }
            }
        }
    }
}
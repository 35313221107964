.intro {

    & .wrap {
        max-width: 800px;
        margin: 0 auto;

        & h2 {
            margin: 0;
            font-size: 1.5em;
        }

        & p {
            margin: 10px 0 20px;
        }
    }
}

@media (--viewport-md-min) {
    .intro {
        
        & .wrap {
    
            & h2 {
                font-size: 2em;
            }

            & p {
                margin: 0 0 20px;
            }
        }
    }
}
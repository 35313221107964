.pt-trainer-slider {
    overflow: hidden;
    margin-bottom: 0;

    & .angle-overlay {

        & svg {
            display: block;

            & path {
                fill: var(--color-gray-light);
            }
        }
    }

    & .background {
        background-color: var(--color-gray-light);
        padding: 30px 0 40px;
        /* text-align: center; */

        & .container {
            /* max-width: 1200px; */
            /* padding-left: 0;
            padding-right: 0; */

            & h2 {
                margin: 0 0 30px;
                font-size: 1.5em;
            }

            & .flickity-viewport {
                overflow: visible;
            }
        }
    }
}

@media (--viewport-md-min) {
    .pt-trainer-slider {
    
        & .background {
            padding: 40px 0 80px;
    
            & .container {
                /* padding-left: 15px;
                padding-right: 15px; */
    
                & h2 {
                    margin: 0 0 30px;
                    font-size: 2em;
                }
            }
        }
    }
}
.pt-icon-tile {
    text-align: center;

    & img {
        width: 80px;
        margin: 0 auto;
    }

    & span {
        display: block;
        font-weight: 900;
        text-transform: uppercase;
        margin: 10px 0 6px;
    }

    & p {
        margin: 6px 0 20px;
    }
}
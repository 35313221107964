.diensten-overzicht {
    width: 100vw;
    overflow: hidden;
}

@media (--viewport-lg-min) {
    .diensten-overzicht {
    
        & .row {
            margin-left: -30px;
            margin-right: -30px;
    
            & [class^='col-'] {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}
.personal-trainer-bio {

    & .wrap {

        & .pt-header {
            margin-bottom: 30px;
            text-align: center;

            & .image {

                & img {
                    display: block;
                    margin: 0 auto 20px;
                    max-width: 240px;
                    border-radius: 100%;
                }
            }

            & .bio {

                & span {
                    display: block;
                    font-size: 1.5em;
                    text-transform: uppercase;
                    font-weight: 900;
                }

                & ul {
                    padding: 0;
                    list-style: none;
                    margin: 0;

                    & li {
                        display: inline-block;

                        &::after {
                            content: "|";
                            padding: 0 6px;
                        }

                        &:last-child::after {
                            content: unset;
                            padding: 0;
                        }
                    }
                }
            }
        }

        & .content {
            & h1,
            & h2,
            & h3,
            & h4 {
                margin-bottom: 4px;
            }

            & h3 {
                color: #505050;
            }

            & p {
                margin-top: 4px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .personal-trainer-bio {

        & .wrap {
            margin-right: 80px;
    
            & .pt-header {
                display: flex;
                margin-bottom: 50px;
                text-align: left;
    
                & .image {
    
                    & img {
                        margin: 0;
                        max-width: 320px;
                    }
                }
    
                & .bio {
                    align-self: center;
                    margin-left: 30px;
    
                    & span {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}
:root {
    --color-input-primary: #fff;

    --color-input-border: #fff;
    --color-input-background-color: #fff;
}

.form-input {
    background-color: var(--color-blue-light);
}

form {

    .field-validation-error {
        color: red;
        display: inline-block;
        transform: translateY(-10px);
        font-size: 14px;
    }
}

fieldset {
    padding: 0;
    border: none;
    margin: 0;
}

.radiobuttonlist {
    margin-bottom: 12px;

    input,
    label {
        margin: 0;
    }
}

input {
    width: 100%;
    padding: 12px;
    border: none;
    /* border-radius: 5px; */
    resize: vertical;
    margin-bottom: 12px;
    background-color: var(--color-input-background-color);
    font-family: var(--base-font-family);

    &::placeholder {
        color: #7b7b7b;
    }

    &[type=radio],
    &[type=checkbox] {
        width: auto;
    }

    &[type=submit] {
        margin-top: 8px;
        padding: 14px 0;
        border: none;
        background-color: var(--color-blue);
        color: var(--color-input-primary);
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            background-color: var(--color-purple);
            /* background-color: #fff; */
            /* color: var(--color-input-primary); */
            color: #fff;
            transition: all .2s ease-in-out;
        }
    }

    & ~ i {
        display: block;
        margin: -12px 0 20px;
        color: red;
    }
}

label {
    color: #fff;
    font-size: 18px;
    margin: 8px 0;
    display: inline-block;
}

.custom-checkbox {
    position: relative;
    display: block;
    min-height: 1.5rem;
    /* padding-left: 1.5rem; */

    &:first-child label {
        margin: 10px 0 3px;
    }

    &:last-child label {
        margin: 3px 0 20px;
    }

    & input[type=checkbox] {
        width: auto;
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {

            & ~ label {

                &::before {
                    box-shadow: 0 0 0 0 transparent;
                    color: var(--color-input-border);
                    border-color: var(--color-input-border);
                    background-color: #fff;
                }

                &::after {
                    display: block;
                }
            }
        }
    }

    & label {
        cursor: pointer;
        position: relative;
        vertical-align: top;
        padding-left: 1.5rem;

        &::before {
            background-color: #dee2e6;

            /* border-radius: .3125rem; */

            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            pointer-events: none;
            background-color: #fff;
            border: 1px solid var(--color-input-border);

            position: absolute;
            top: .25rem;
            /* left: -1.5rem; */
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
        }

        &::after {
            content: "";
            position: absolute;
            display: none;

            top: .35rem;
            /* left: -1.1rem; */
            left: 0.35rem;
            width: 5px;
            height: 10px;
            border: solid #000;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.custom-radio {
    position: relative;
    display: block;
    min-height: 1.5rem;
    /* padding-left: 1.5rem; */

    &:first-child label {
        margin: 10px 0 3px;
    }

    &:last-child label {
        margin: 3px 0 20px;
    }

    & input[type=radio] {
        width: auto;
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {

            & ~ label {

                &::before {
                    box-shadow: 0 0 0 0 transparent;
                    color: #fff;
                    border-color: var(--color-input-border);
                    background-color: #fff;
                }

                &::after {
                    display: block;
                }
            }
        }
    }

    & label {
        cursor: pointer;
        position: relative;
        vertical-align: top;
        margin: 3px 0;
        padding-left: 1.5rem;

        &::before {
            background-color: #dee2e6;
            border-radius: 100%;
            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            pointer-events: none;
            background-color: #fff;
            border: 1px solid var(--color-input-border);
            position: absolute;
            top: .25rem;
            /* left: -1.5rem; */
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            content: "";
        }

        &::after {
            content: "";
            position: absolute;
            display: none;
            top: calc(.25rem + 6px);
            /* left: calc(-1.5rem + 6px); */
            left: 6px;
            width: 6px;
            height: 6px;
            background-color: #000;
            border-radius: 100%;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.custom-select {
    border: none;
    width: 100%;
    padding: 0;
    /* border-radius: 5px; */
    overflow: hidden;
    margin-bottom: 12px;
    background: var(--color-input-background-color) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    & select {
        cursor: pointer;
        padding: 14px 12px;
        background-color: var(--color-input-background-color);
        width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        font-family: var(--base-font-family);
        background-image: none;
        -webkit-appearance: none;
    }
}

textarea {
    width: 100%;
    padding: 12px;
    border: none;
    /* border-radius: 5px; */
    resize: vertical;
    margin-bottom: 12px;
    background-color: var(--color-input-background-color);;
    font-family: var(--base-font-family);

    &::placeholder {
        color: #7b7b7b;
    }
}

@media (--viewport-sm-min) {
    input {
        margin-bottom: 14px;

        &[type=submit] {
            width: auto;
            padding: 14px 30px;
        }
    }

    .custom-select {
        margin-bottom: 14px;
    }
}
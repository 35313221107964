.rooster-tile {
    padding: 12px;
    background-color: var(--color-gray-light);
    margin-bottom: 12px;
    cursor: pointer;
    transition: .2s;

    & .rooster-tile-wrap {
        display: flex;
        align-items: center;

        & .time {
            display: block;
            font-size: 1.5em;
            color: var(--color-purple);
            padding: 0 10px;
            font-weight: 700;
        }

        & .lesson {
            margin-left: 12px;

            & .lesson-name {
                margin: 0;
                font-weight: 400;
                font-size: 1.1em;
                color: var(--color-purple);

            }

            & .trainer {
                font-size: 14px;
            }
        }
    }

    &:hover {
        background-color: var(--color-blue);
        transition: .2s;

        & .rooster-tile-wrap {

            & .time {
                color: #fff;
            }

            & .lesson {
    
                & .lesson-name {
                    color: #fff;
    
                }

                & .trainer {
                    color: #fff;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .rooster-tile {
        padding: 16px;
        margin-bottom: 12px;
    
        & .rooster-tile-wrap {
    
            & .time {
                font-size: 2em;
            }

            & .lesson {
                margin-left: 16px;
            }
        }
    }
}

/* Modal */
.rooster-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    & .rooster-modal__container {
        width: 100%;
        max-width: 600px;
        margin: 80px 15px;
        max-height: 100vh;
        overflow-y: auto;
        box-sizing: border-box;

        & .rooster-modal__wrap {
            background-color: var(--color-purple);

            & .rooster-modal__top {
                position: relative;
                overflow: hidden;

                & .close-button {
                    display: inline-block;
                    position: absolute;
                    z-index: 10001;
                    top: 14px;
                    right: 20px;
                    width: 20px;
                    height: 32px;
                    cursor: pointer;

                    & svg {
                        & path {
                            fill: #fff;
                        }
                    }
                }
    
                & .image {
    
                    & img {
                        display: block;
                        width: 100%;
                        object-fit: cover;
                    }
                }
    
                & .content {
                    position: absolute;
                    bottom: 0;
                    padding: 16px;
                    
                    & h2 {
                        color: #fff;
                        margin: 0 0 8px;
                    }
    
                    & .trainer {
                        display: flex;
                        align-items: center;
    
                        & img {
                            display: block;
                            width: 80px;
                            border-radius: 100%;
                            margin-right: 16px;
                        }
    
                        & span {
                            display: block;
                            color: #fff;
                        }
                    }
                }
            }
    
            & .rooster-modal__bottom {
                padding: 30px 30px 30px;
                color: #fff;

                & span {
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 1.15em;
                }

                & p {
                    margin: 0;
                }
            }
        }
    }

    & svg {
        & path {
            fill: var(--color-purple);
        }
    }
}
  
  /**************************\
    Modal Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .rooster-modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .rooster-modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .rooster-modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .rooster-modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide .rooster-modal__container,
  .micromodal-slide .rooster-modal__overlay {
    will-change: transform;
  }
  
@media (--viewport-sm-max) {
    .hide-mobile {
        display: none;
    }
    .reverse-mobile-inschrijf {
        flex-direction: column-reverse;
    }
}

.inschrijf-pagina {

    .custom-submit {
        display: flex;
        align-items: center;

        &.loading {
            pointer-events: none;

            input {
                background-color: #686868;
            }

            &::after {
                display: inline-block;
            }
        }

        &::after {
            content: "";
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath d='M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80z'/%3E%3C/svg%3E");
            display: none;
            width: 30px;
            height: 30px;
            background-size: 30px;
            transform-origin: center;
            animation: rotation 1.1s linear infinite reverse;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(-359deg);
            }
        }
    }

    .hidden-vue {
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    .signature-input {
        position: relative;
        margin: 0 0 22px;

        .signature-input-wrap {
            position: relative;

            .signature-pad {
                /* border: 1px solid #021a2b; */
                background-color: #ffffff;
            }

            .signature-input-title {
                position: absolute;
                top: 12px;
                left: 12px;
                color: gray;
                font-size: 14px;
                pointer-events: none;
            }
        }

        .signature-clear {
            display: inline-block;
            padding: 6px 14px;
            background-color: #213A86;
            color: #fff;
            cursor: pointer;
        }

        .signature-hidden-input textarea {
            position: absolute;
            opacity: 0;
            height: 1px;
            width: 1px;
            padding: 0;
            margin: 0;
            top: 36px;
            left: 62px;
        }
    }

    & svg {
        margin-bottom: -1px;

        & path {
            fill: var(--color-gray-light);
        }
    }

    & .wrap {
        background-color: var(--color-gray-light);
        padding: 15px;

        form {
            & h3 {
                margin: 20px 0 4px;
                color: #505050;
            }

            & .abbo-wrap {
                margin-bottom: 34px;

                & .abbo-container {
                    position: relative;

                    & input[type=radio] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                        bottom: 0;
                        left: 50%;

                        &:checked + .abbo-label .abbo-content {
                            border: 2px var(--color-blue-light) solid;
                            opacity: .8;
                        }
                    }

                    & input[type=checkbox] {
                        width: auto;
                        box-sizing: border-box;
                        padding: 0;
                        position: absolute;
                        z-index: -1;
                        opacity: 0;

                        &:checked + .abbo-label .abbo-content {
                            border: 2px var(--color-blue-light) solid;
                            opacity: .8;
                        }
                    }

                    & .abbo-label {
                        display: block;
                        color: #000;
                        text-align: center;

                        & .abbo-content {
                            background-color: #fff;
                            border: 0px #fff solid;
                            padding: 40px 20px 74px;
                            cursor: pointer;
                            background-position: center;
                            background-size: cover;
                            border: 2px #f5f5f5 solid;

                            & img {
                                width: 100%;
                                max-width: 84px;
                            }

                            & span {
                                display: block;
                                color: #fff;
                                font-size: 1.2em;
                                font-family: var(--heading-font-family);
                                font-weight: 600;

                                &.weken {
                                    font-size: 1.5em;
                                    text-transform: uppercase;
                                    /* color: var(--color-blue-light); */
                                }

                                &.abonnement {
                                    /* text-transform: uppercase; */
                                    margin: 0 0 16px;
                                    color: #fff;
                                }

                                &.kosten {
                                    margin: 16px 0 0;
                                    color: #fff;
                                }
                            }

                            &--leeftijd {
                                padding: 80px 20px;

                                & span {

                                    &.abonnement {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                            &--tijd {

                                & img {
                                    opacity: 1;
                                }
                            }

                            &--upgrade {

                                & span {
                                    color: #fff;

                                    &.abonnement {
                                        margin: 8px 0 12px;
                                        color: #fff;
                                    }

                                    &.kosten {
                                        margin: 16px 0 0;
                                        color: #fff;
                                    }
                                }
                            }

                            &--meest-gekozen {
                                position: relative;
                                overflow: hidden;

                                & .corner-ribbon {
                                    font-family: var(--heading-font-family);
                                    width: 200px;
                                    background: #2c7;
                                    position: absolute;
                                    top: 25px;
                                    left: -50px;
                                    text-align: center;
                                    font-size: 17px;
                                    line-height: 42px;
                                    /* letter-spacing: 1px; */
                                    color: #fff;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    box-shadow: 0 0 3px rgba(0,0,0,.3);
                                }
                            }

                            .actie-label {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                background-color: var(--color-blue-light);
                                color: #fff;
                                padding: 6px;
                            }
                        }
                    }
                }
            }

            & label {
                color: #000;
            }

            & .custom-checkbox label:before {
                border: 1px solid #000;
            }

            & input[type=submit] {
                /* float: right; */
            }

            & .abbonement-voorbeeld {
                width: 100%;
                display: block;
                max-width: 400px;
                margin-bottom: 30px;
                align-self: center;

                & .av-wrap {
                    background-color: #fff;

                    & .av-image {

                        & img {
                            display: block;
                            width: 100%;
                        }
                    }

                    & .av-abbo {

                        & ul {
                            list-style: none;
                            padding: 30px;
                            margin: 0;

                            & li {
                                display: block;
                                margin-bottom: 12px;

                                & span {
                                    display: block;
                                }

                                & .title {
                                    font-family: "Cabin Condensed";
                                    color: #004a8e;
                                    font-size: 22px;
                                }
                            }

                            & hr {
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }

            & .checked-list {
                display: none;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .inschrijf-pagina {

        & .wrap {
            padding: 30px;

            form {

                & .abbo-wrap {
                    display: flex;
                    margin-left: -10px;
                    margin-right: -10px;

                    & .abbo-container {
                        width: calc(100% / 3);
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .hide-desktop {
        display: none;
    }

    .inschrijf-pagina {

        & .wrap {
            padding: 35px;

            form {

                & .abbo-wrap {
                    margin-left: -20px;
                    margin-right: -20px;

                    & .abbo-container {
                        padding: 0 20px;
                    }
                }

                & .checked-list {
                    display: none;
                }
            }
        }
    }
}
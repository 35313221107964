.tarieven-pagina {

    & svg {
        margin-bottom: -1px;
        
        & path {
            fill: var(--color-gray-light);
        }
    }

    & .wrap {
        background-color: var(--color-gray-light);
        padding: 15px;

        & .tarieven-intro {
            display: block;
            max-width: 900px;
        }
        & h3 {
            margin: 20px 0 4px;
            color: #505050;
        }

        & .abbo-wrap {

            & .abbo-container {
                display: block;
                color: #000;
                text-align: center;
                margin-bottom: 16px;
        
                & .abbo-content {
                    background-color: #fff;
                    border: 2px #fff solid;
                    padding: 20px 10px;

                    & img {
                        width: 100%;
                        max-width: 64px;
                    }
    
                    & span {
                        display: block;

                        &.weken {
                            font-size: 1.5em;
                            font-weight: 900;
                            text-transform: uppercase;

                            &.blue-light { color: var(--color-blue-light); }
                            &.blue { color: var(--color-blue); }
                            &.purple { color: var(--color-purple); }
                        }

                        &.abonnement {
                            margin: 0 0 16px;
                        }

                        &.kosten {
                            margin: 16px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .tarieven-pagina {
    
        & .wrap {
            padding: 30px;
    
            & .abbo-wrap {
                display: flex;
                margin-left: -10px;
                margin-right: -10px;
    
                & .abbo-container {
                    width: calc(100% / 3);
                    padding: 0 10px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .tarieven-pagina {
    
        & .wrap {
            padding: 35px;
    
            & .abbo-wrap {
                margin-left: -20px;
                margin-right: -20px;
    
                & .abbo-container {
                    padding: 0 20px;
                }
            }

            & .checked-list {
                display: block;
            }
        }
    }
}
.image-content {

    & .wrap {
        display: flex;
        flex-direction: column-reverse;

        & .image {
            position: relative;
            overflow: hidden;

            & img {
                border: solid 1px #FFF;
            }

            & .before {
                display: none;
            }

            & .after {
                position: absolute;
                bottom: 0;
                width: 110%;

                & svg {
                    display: block;

                    & path {
                        fill: #fff;
                    }
                }
            }
        }

        & .content {
            margin-bottom: 30px;

            & h2 {
                margin: 0;
                font-size: 1.5em;
            }

            & p {
                margin: 10px 0 20px;
            }

            & table {
                border-collapse: collapse;
                width: 100%;
                border: none;
                margin-bottom: 20px;

                & tbody {

                    & tr {

                        & td {
                            border: none;

                            & ul {
                                list-style-image: url(/dist/images/vinkje-oranje.png);
                                margin: 2px 0;
                                & li {

                                }
                            }
                        }
                    }
                }
            }

            & a.button {
                margin-right: 10px;
                margin-bottom: 5px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-content {

        & .wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            & .image {
                width: 50%;
    
                & .before {
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 110%;
    
                    & svg {
                        display: block;
    
                        & path {
                            fill: #fff;
                        }
                    }
                }
            }
    
            & .content {
                width: 50%;
                padding-left: 80px;
    
                & h2 {
                    font-size: 2em;
                }
    
                & p {
                    margin: 0 0 20px;
                }
            }
        }
    }
}
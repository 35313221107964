.basic-detail-content {
    max-width: 900px;

    & h2 {
        margin: 8px 0 8px;
    }

    & p {
        margin: .5em 0 1em;
    }
}
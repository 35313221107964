.image-contact {
    position: relative;
    overflow: hidden;
    margin-top: 0;

    & .wrap {
        background-color: var(--color-blue-light);

        & .content {
            padding: 40px 20px 60px;

            & h2 {
                margin: 0 0 20px;
                font-size: 1.5em;
                line-height: 1.2;
                color: #fff;
            }

            & .button {
                color: #000;
                background-color: #fff;

                &:hover {
                    color: #fff;
                    background-color: var(--color-blue);
                }
            }
        }

        & .image {
            display: none;
        }
    }

    & svg {
        display: block;
        position: absolute;
        z-index: 2;
        bottom: -1px;
        width: 100%;

        & path {
            fill: #fff;
        }
    }

    &.reverse .svg-bottom {
        transform: scaleX(-1);
    }
}

@media (--viewport-md-min) {
    .image-contact {
        position: relative;
        overflow: hidden;
    
        & .wrap {
            display: flex;
    
            & .content {
                align-self: center;
                width: 50%;
                padding: 50px 50px 90px;
    
                & h2 {
                    /* font-size: 40px; */
                }
            }
    
            & .image {
                display: block;
                width: 50%;
                position: relative;
                overflow: hidden;
    
                & figure {
                    height: 100%;
    
                    & img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            
                & svg {
                    display: block;
                    /* height: 100%; */

                    position: absolute;
                    z-index: 1;
                    right: -1px;
                    bottom: -5%;
                    height: 110%;
                    width: auto;
        
                    & path {
                        fill: var(--color-blue-light);
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .image-contact {
    
        & .wrap {
    
            & .content {
                padding: 80px 80px 120px;
    
                & h2 {
                    /* font-size: 60px; */
                }
            }
        }
    }
}
.actie-dienst-tile {
    margin-bottom: 30px;

    & .content {

        & h3 {
            margin-bottom: 8px;
        }

        & p {
            margin-top: 8px;
        }
    }
}

@media (--viewport-md-min) {
    .actie-dienst-tile {
        margin-bottom: 0;
    }
}
.hero {
    margin-top: 0;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, .2));

    & .container {

        & .wrap {
            display: flex;
            align-items: center;
            min-height: 440px;

            & .content {
                max-width: 800px;
                padding: 100px 0 90px;

                & h1 {
                    margin: 0;
                    color: #fff;
                    line-height: 1.2;
                }

                & p {
                    margin: 0px 0 20px;
                    color: #fff;
                    font-size: 18px;
                }

                & table {
                    border-collapse: collapse;
                    width: 100%;
                    border: none;
                    margin-bottom: 1em;

                    & tbody {

                        & tr {

                            & td {
                                border: none;

                                & ul {
                                    margin: 2px 0;
                                    color: #fff;
                                    font-size: 18px;
                                    padding: 0 0 0 26px;
                                    list-style-image: url(/dist/images/vinkje-oranje.png);

                                    & li {

                                    }
                                }
                            }
                        }
                    }
                }

                & .button {
                    font-size: 16px;
                }
            }
        }
    }

    & .background-image {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: #000;

        & figure {
            height: 100%;

            & img,
            & video {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .video-fullscreen {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 480px;

            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 854px;
                height: 480px;
                transform: translate(-50%, -50%);
                max-width: unset;
            }
        }
    }

    & .angle-overlay {
        position: absolute;
        bottom: -1px;
        left: -5%;
        width: 110%;

        & svg {
            display: block;

            & path {
                fill: #fff;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .hero {

        .background-image {

            .video-fullscreen {
                height: 720px;

                iframe {
                    width: 1280px;
                    height: 720px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .hero {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.52), rgba(255, 255, 255, 0));

        & .container {

            & .wrap {
                min-height: 720px;

                & .content {
                    max-width: 800px;
                    padding: 160px 0 180px;

                    & h1 {
                        font-size: 60px;
                        line-height: 1.1;
                    }

                    & p {
                        font-size: 20px;
                    }

                    & .button {
                        font-size: 20px;
                    }
                }
            }
        }

        /* .background-image {

            .video-fullscreen {
                height: 720px;

                iframe {
                    width: 1280px;
                    height: 720px;
                }
            }
        } */
    }
}

@media (--viewport-lg-min) {
    .hero {

        & .container {

            & .wrap {
                min-height: 780px;

                & .content {
                    max-width: 800px;
                    padding: 220px 0 240px;

                    & h1 {
                        font-size: 68px;
                    }
                }
            }
        }

        .background-image {

            .video-fullscreen {
                height: 780px;

                iframe {
                    width: 1920px;
                    height: 780px;
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    .hero {

        .background-image {

            .video-fullscreen {
                height: 780px;

                iframe {
                    width: 100%;
                    height: 56.25vw;
                }
            }
        }
    }
}


